<template>
    <div> <!-- IE11 no toma el atributo :class definido en el elemento root del template -->
        <div class="phi-thread" :class="{unread: thread.posts.unread > 0}">

            <div class="phi-thread-header phi-media">
                <div class="phi-media-figure" @click="$emit('toggle')">
                    <phi-person-avatar :person="thread.posts.latest.author" size="38" :fliped="selected">
                        <div v-if="$listeners.toggle" slot="back" class="post-checkbox">
                            <mu-icon v-show="selected" class="icon-checked" value="check" />
                        </div>
                    </phi-person-avatar>
                </div>

                <div class="phi-media-body" @click="$emit('click')">
                    <div class="phi-row">
                        <div class="thread-title phi-column-wide" v-text="thread.title"></div>
                        <div class="thread-date" v-text="threadDate"></div>
                    </div>

                    <div class="phi-row">
                        <ul class="thread-authors">
                            <li v-for="author in sortedAuthors" :key="author.id" :class="{unread: author.isUnread}">
                                <template v-if="author.id == thread.person">
                                    <span class="author-firstname">{{ $t("PhiThreadListItem.authors.Me") }}</span>
                                </template>
                                <template v-else>
                                    <span class="author-firstname" v-text="author.firstName"></span>
                                    <span class="author-lastname" v-text="author.lastName"></span>
                                </template>
                            </li>
                        </ul>
                        <div class="thread-posts-count" v-if="thread.posts.total > 1" v-text="thread.posts.total"></div>
                    </div>

                    <div class="thread-latest-preview" v-text="thread.posts.latest.preview"></div>

                    <ul v-if="thread.blocks" class="thread-blocks">
                        <template v-for="(count, type) in thread.blocks">
                            <li v-if="typeof blockIcons[type] != 'undefined'" :key="type">
                                <mu-icon :value="blockIcons[type]"></mu-icon>
                            </li>
                        </template>
                    </ul>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PhiPersonAvatar from '@/components/Phi/Person/Avatar.vue';

export default {
    name: "phi-thread",
    props: ["value", "selected"],
    components: {PhiPersonAvatar},

    data() {
        return {
            thread: this.value,

            blockIcons: {
                "files": "attachment",
                "form": "assignment",
                "html": "text_format",
                "youtube": "movie",
                "event": "event"
            }
        };
    },

    computed: {
        threadDate() {
            return this.$date(this.thread.incomingDate);
        },

        sortedAuthors() {
            // Mostrar los autores no leidos de primero
            return this.thread.authors.list.sort((a,b) => {
                if (a.isUnread && !b.isUnread) {
                    return -1;
                } else if (b.isUnread) {
                    return 1;
                }

                return a.lastName > b.lastName ? 1 : -1;
            });
        }
    },

    watch: {
        value(newValue) {
            this.thread = newValue;
        }
    },

    i18n: {
        "en": {
            "PhiThreadListItem.authors.Me": "Me"
        },

        "es": {
            "PhiThreadListItem.authors.Me": "Yo"
        },

        "de": {
            "PhiThreadListItem.authors.Me": "Me"
        },

        "fr": {
            "PhiThreadListItem.authors.Me": "Moi"
        },

        "it": {
            "PhiThreadListItem.authors.Me": "Io"
        },
    }
}
</script>


<style lang="scss" scoped>
.phi-thread {
    cursor: pointer;

    .phi-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .phi-column-wide {
            flex: 1;
        }
    }

    & > .phi-media {
        padding: 0;

        .phi-media-figure {

            min-width: 52px;
            min-height: 52px;
            width: 52px;
            height: 52px;

            text-align: center;
            margin: 0;
            padding: 8px;
            padding-top: 12px;

            .post-checkbox {
                position: absolute;
                top: 8px;
                left: 8px;
                text-align: center;

                .icon-unchecked {
                    text-shadow: 0 2px 6px rgba(0, 0, 0, .8);
                }
            }
        }

        .phi-media-body {
            min-width: 0; // Con esto funcionan bien la truncada del titulo (overflow: ellipsis) https://css-tricks.com/flexbox-truncated-text/
            padding: 8px;
            padding-bottom: 12px;
        }
    }

    .thread-date {
        font-size: .8em;
        color: #999;
        padding: 1px 8px;
    }

    .thread-title {
        font-size: 1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .thread-authors {
        list-style: none;
        margin: 0;
        padding: 0;

        font-size: .8em;
        color: #999;

        li {
            display: inline-block;
            margin-right: .5em;

            &::after {
                content: ", ";
            }

            &:last-child::after {
                content: "";
            }

            &.unread {
                font-weight: bold;
                color: #444;
            }

            /* Ocultar apellidos cuando hay mas de un author */
            .author-lastname {
                display: none;
                margin-left: .3em;
            }

            &:last-child:first-child {
                .author-lastname {
                    display: inline;
                }
            }
        }
    }

    .thread-posts-count {
        font-size: .8em;
        color: #777;
        margin-left: 2px;
    }

    .thread-latest-preview {
        font-size: .9em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


    &.unread {
        .thread-title {
            font-weight: bold;
        }
    }


    .thread-blocks {
        list-style: none;
        padding: 0;
        margin: 0;

        display: flex;

        li {
            display: flex;

            margin: 0 .5em 0 0;
            padding: 4px 0;

            .count {
                color: #aaa;
                font-size: 11px;
                white-space: nowrap;
            }

            .mu-icon {
                color: #aaa;
                font-size: 16px;
                margin-right: 6px;
            }
        }
    }


}
</style>