<template>
    <div id="thread-container" class="move-left">
        <transition name="slide">
            <router-view></router-view>
        </transition>

        <phi-page class="page-feed" :loading="isLoading" color="#f3f3f3">

            <div slot="top">
                <form @submit.prevent="applyFilters(rawFilters)">
                    <phi-page-top-panel :open="searchIsOpen">
                        <div slot="toolbar">
                            <mu-icon-button @click="closeSearch()" icon="arrow_back" />
                            <h1>{{ $t("StateThreadFeed.Search") }}</h1>
                            <mu-icon-button @click="applyFilters(null)" icon="clear" />
                        </div>


                        <phi-thread-filters v-model="rawFilters"></phi-thread-filters>

                        <div slot="footer">
                            <button type="submit">{{ $t("StateThreadFeed.Search") }}</button>
                            <button type="button" class="cancel" @click="applyFilters(filters)">{{ $t("StateThreadFeed.Cancel") }}</button>
                        </div>
                    </phi-page-top-panel>
                </form>
            </div>

            <div slot="toolbar">

                <!-- Selection mode -->
                <template v-if="selection.length > 0">

                    <mu-icon-button @click="$refs.threads.select('none')" icon="arrow_back" />
                    <h1 v-text="selection.length"></h1>

                    <button @click="$refs.threads.move('feed')" v-if="allSelectionIsArchived">
                        <mu-icon value="move_to_inbox" />
                    </button>

                    <button @click="$refs.threads.move('archive')" v-else>
                        <mu-icon value="archive" />
                    </button>

                    <button @click="$refs.threads.move('trash')">
                        <mu-icon value="delete" />
                    </button>

                    <button @click="$refs.threads.move('read')" v-if="allSelectionIsUnread">
                        <mu-icon value="drafts" />
                    </button>

                    <button @click="$refs.threads.move('unread')" v-else>
                        <mu-icon value="markunread" />
                    </button>

                </template>
                <template v-else>

                    <!-- Search results mode -->
                    <template v-if="!!filters">
                        <mu-icon-button @click="applyFilters(null)" icon="arrow_back" />
                        <h1 @click="openSearch()">{{ $t("StateThreadFeed.Results") }}</h1>
                        <mu-icon-button @click="openSearch()" icon="search" />
                    </template>

                    <!-- default toolbar -->
                    <template v-else>
                        <mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
                        <h1>{{ currentType.plural }}</h1>
                        <mu-icon-button @click="openSearch()" icon="search" />
                    </template>

                </template>
            </div>

            <phi-thread-feed
                ref="threads"
                :personId="personId"
                :folder="folder"
                :type="type"
                :filters="filters"
                :scroll-container="$el ? $el.querySelector('.page-feed .phi-page-body') : null"
                @click="openThread($event)"
                @select="handleSelection($event)"
            ></phi-thread-feed>
        </phi-page>

    </div>
</template>

<script>
import PhiPageTopPanel from '@/components/Phi/Page/TopPanel.vue';
import PhiThreadFeed from '@/components/Phi/Thread/Feed.vue';
import PhiThreadFilters from '@/components/Phi/Thread/Filters.vue';

export default {
	components: {
        PhiPageTopPanel,
		PhiThreadFeed,
        PhiThreadFilters
	},

	props: {
		personId: {
            type: String,
            required: false,
			default() {
				return this.$store.state.user.id;
			}
		},

		folder: {
            type: String,
            required: false,
			default: "inbox"
		}
	},

	data() {
		return {
            type: this.$route.query.type,

            rawFilters: {
                type: this.$route.query.type,
                folder: this.folder
            },
            filters: null,

            selection: [],
            searchIsOpen: false
		}
	},

    computed: {
        isLoading() {
            // return this.$refs.threads && this.$refs.threads.collection.isLoading;
            return this.$store.state.api.isLoading;
        },

        allSelectionIsUnread() {
            return this.selection.every(thread => thread.posts.unread > 0);
        },

        allSelectionIsArchived() {
            return this.selection.every(thread => thread.archiveDate != null);
        },

        currentType() {
            return this.$store.getters.getType(this.type);
        }
    },

    watch: {
		"$route.query.type"(newValue) {
            if (this.$route.name != "feed") {
                return;
            }

            if (this.type == newValue) {
                return;
            }
            this.type = newValue;
        },

        folder(newValue) {
            if (this.rawFilters) {
                this.rawFilters.folder = newValue;
            }
        },

		type() {
			let query = this.type ? {type: this.type} : null;
            this.$router.replace({name: this.$route.name, folder: this.folder, query});

            if (this.rawFilters) {
                this.rawFilters.type = this.type;
            }
		}
    },

    mounted() {
        this.$store.commit("onBackButton", () => {
            if (this.searchIsOpen) {
                this.closeSearch();
                return false;
            }

            if (this.selection.length > 0) {
                this.$refs.threads.select('none');
                return false;
            }
        });
    },


	methods: {
        openSearch() {
            this.searchIsOpen = true;
            this.$nextTick(() => this.$el.querySelector('.phi-thread-filters input').focus());
        },

        closeSearch() {
            this.searchIsOpen = false;
        },

        applyFilters(filters) {
            this.filters = filters;
            this.searchIsOpen = false;

            this.rawFilters = filters;
            if (!this.rawFilters) {
                this.rawFilters = {
                    type: this.$route.query.type,
                    folder: this.folder
                }
            }

            // Hide android keyboard !!!
            document.querySelectorAll('input').forEach(el => el.blur());
        },

		openThread(thread) {
            if (this.$store.state.user && this.$store.state.user.id == this.personId) {
                // Mark local object as read
                thread.posts.unread = 0;
                thread.authors.list.forEach(author => author.isUnread = false);
                this.$refs.threads.collection.override();

                // override the cached dashboard count as well
                this.$store.commit("decreaseUnread", thread.type);
            }

			this.$router.push({name: 'thread', params:{threadId: thread.id}});
        },

        handleSelection(event) {
            this.selection = event.selection;
        }
    },

    i18n: {
        "en": {
            "StateThreadFeed.Search": "Search",
            "StateThreadFeed.Results": "Results",
            "StateThreadFeed.Cancel": "$t(action.cancel)"
        },

        "es": {
            "StateThreadFeed.Search": "Buscar",
            "StateThreadFeed.Results": "Resultados",
            "StateThreadFeed.Cancel": "$t(action.cancel)"
        }
    }
}
</script>

<style scoped lang="scss">
#thread-container {
    height: 100%;

    .phi-page {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        z-index: 0;
    }

    #thread {
        z-index: 1;
    }
}

.phi-page {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.toolbar-search {
    flex: 1;

    input {
        display: block;
        font-size: 1em;
        font-family: inherit;
        width: 100%;

        border: 0;
        border-bottom: 1px solid #ccc;
        background: transparent;
    }
}

.thread-listitem {
    border-bottom: 1px solid #ececec;

    &:last-child {
        border: 0;
    }
}

.menu-label {
    display: block;
    margin: 0;
    padding: 0 9px;
    font-size: 14px;
    color: #999;
}

.type-picker {
    border: none;
    background: transparent;
    font-size: 1em;
    width: 110px;
}

.message-picker {
    border: none;
    background: transparent;
    font-size: 1em;
}

.loadNext {
    display: block;
    width: 100%;
    padding: 12px;
    margin: 12px 0;
    background: rgba(0, 0, 0, 0.1);
    color: #333;
    border: 0;
    cursor: pointer;
}

.empty {
    text-align: center;
    padding-top: 96px;
    background: url('../../assets/cactus.png') no-repeat top center;
    margin-top: 64px;

    p {
        font-size: 1.2em;
        margin: 12px 0;
        color: #666;
    }
}

.phi-menu li {
    white-space: nowrap;
}

.selection-count {
    width: auto;
    display: flex;
    align-items: center;

    span {
        font-size: 0.8em;
        margin-right: .5em;
    }
}
</style>