<template>
    <div class="phi-thread-filters">
        <div class="filter-field filter-description">
            <div class="field-label">{{ $t('PhiThreadFilters.Contains') }}</div>
            <div class="field-input">
                <input type="text" v-model="filters.search.description" />
            </div>
        </div>

        <div class="filter-field filter-from">
            <div class="field-label">{{ $t('PhiThreadFilters.From') }}</div>
            <div class="field-input">
                <input type="text" v-model="filters.search.from" />
            </div>
        </div>

        <div class="filter-field filter-title">
            <div class="field-label">{{ $t('PhiThreadFilters.Subject') }}</div>
            <div class="field-input">
                <input type="text" v-model="filters.search.title" />
            </div>
        </div>

        <div class="filter-field filter-date">
            <div class="field-label">{{ $t('PhiThreadFilters.DateAround') }}</div>
            <div class="field-input">

                <select v-model="filters.date.around">
                    <option value="1">1 {{ $t('PhiThreadFilters.day') }}</option>
                    <option value="3">3 {{ $t('PhiThreadFilters.days') }}</option>
                    <option value="7">1 {{ $t('PhiThreadFilters.week') }}</option>
                    <option value="14">2 {{ $t('PhiThreadFilters.weeks') }}</option>
                    <option value="30">1 {{ $t('PhiThreadFilters.month') }}</option>
                </select>

                <date-picker v-model="filters.date.start"></date-picker>
            </div>
        </div>

        <div class="filter-field filter-folder">
            <div class="field-label">{{ $t('PhiThreadFilters.Folder') }}</div>
            <div class="field-input">
                <select v-model="filters.folder">
                    <option value="inbox">{{ $t('PhiThreadFilters.Inbox') }}</option>
                    <option value="archive">{{ $t('PhiThreadFilters.Archive') }}</option>
                    <option value="trash">{{ $t('PhiThreadFilters.Trash') }}</option>
                </select>
            </div>
        </div>

        <div class="filter-field filter-type">
            <div class="field-label">{{ $t('PhiThreadFilters.Type') }}</div>
            <div class="field-input">
                <select v-model="filters.type">
                    <option :value="null">{{ $t('PhiThreadFilters.AnyType') }}</option>
                    <option
                        v-for="type in $store.getters.types"
                        :key="type.singular"
                        :value="type.singular"
                    >{{ type.plural }}</option>
                </select>
            </div>
        </div>

        <div class="filter-field filter-state">
            <div class="field-label">{{ $t('PhiThreadFilters.Status') }}</div>
            <div class="field-input">
                <select v-model="filters.state">
                    <option :value="null">{{ $t('PhiThreadFilters.StatusAny') }}</option>
                    <option value="read">{{ $t('PhiThreadFilters.StatusRead') }}</option>
                    <option value="unread">{{ $t('PhiThreadFilters.StatusUnread') }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';

export default {
    name: "phi-thread-filters",
    components: {DatePicker},

    props: {
        value: {
            type: Object,
            required: false,
            default: {}
        }
    },

    data() {
        return {
            filters: {
                search: {
                    description: null,
                    from: null,
                    title: null
                },
                date: {
                    start: null,
                    around: 1,
                    end: null
                },
                folder: null,
                type: null,
                state: null,
                q: null
            },

            doNotTriggerInput: true
        }
    },

    mounted() {
        this.setLocalFilters(this.value);
    },

    methods: {
        setLocalFilters(newValue) {
            let defaultValues = {
                search: {
                    description: null,
                    from: null,
                    title: null
                },
                date: {
                    start: null,
                    around: 1,
                    end: null
                },
                folder: 'inbox',
                type: null,
                state: null,
                q: null
            };
            let incoming = newValue ? JSON.parse(JSON.stringify(newValue)) : {};

            this.doNotTriggerInput = true;
            this.filters = Object.assign(defaultValues, incoming);
        }
    },

    watch: {
        value(newValue) {
            this.setLocalFilters(newValue);
        },

        filters: {
            deep: true,
            handler(value) {
                if (this.doNotTriggerInput) {
                    this.doNotTriggerInput = false;
                    return;
                }

                this.$emit("input", JSON.parse(JSON.stringify(value)));
            }
        }
    },

    i18n: {
        "en": {
            "PhiThreadFilters.Contains": "Contains",
            "PhiThreadFilters.From": "From",
            "PhiThreadFilters.Subject": "Subject",
            "PhiThreadFilters.DateAround": "Date around",

            "PhiThreadFilters.day": "day",
            "PhiThreadFilters.days": "days",
            "PhiThreadFilters.week": "week",
            "PhiThreadFilters.weeks": "weeks",
            "PhiThreadFilters.month": "month",

            "PhiThreadFilters.Folder": "Folder",
            "PhiThreadFilters.Inbox": "Inbox",
            "PhiThreadFilters.Archive": "Archive",
            "PhiThreadFilters.Trash": "Trash",

            "PhiThreadFilters.Type": "Type",
            "PhiThreadFilters.AnyType": "All types",

            "PhiThreadFilters.Status": "Status",
            "PhiThreadFilters.StatusAny": "Read and unread",
            "PhiThreadFilters.StatusRead": "Read",
            "PhiThreadFilters.StatusUnread": "Unread"
        },

        "es": {
            "PhiThreadFilters.Contains": "Contiene",
            "PhiThreadFilters.From": "De",
            "PhiThreadFilters.Subject": "Asunto",
            "PhiThreadFilters.DateAround": "Fecha por",

            "PhiThreadFilters.day": "día",
            "PhiThreadFilters.days": "días",
            "PhiThreadFilters.week": "semana",
            "PhiThreadFilters.weeks": "semanas",
            "PhiThreadFilters.month": "mes",

            "PhiThreadFilters.Folder": "Carpeta",
            "PhiThreadFilters.Inbox": "Entrada",
            "PhiThreadFilters.Archive": "Archivados",
            "PhiThreadFilters.Trash": "Papelera",

            "PhiThreadFilters.Type": "Tipo",
            "PhiThreadFilters.AnyType": "Cualquier tipo",

            "PhiThreadFilters.Status": "Estado",
            "PhiThreadFilters.StatusAny": "Leído y no leído",
            "PhiThreadFilters.StatusRead": "Leído",
            "PhiThreadFilters.StatusUnread": "No leído"
        }
    }
}
</script>

<style lang="scss" scoped>
.filter-field {
    padding: 8px;
    display: flex;
    align-items: center;

    .field-label {
        white-space: nowrap;
        min-width: 100px;
        font-size: .9em;
        opacity: .7;
    }

    .field-input {
        flex: 1;

        display: flex;
        flex-wrap: wrap;

        & > * {
            flex: 1;
        }

        input, select {
            display: block;
            border: 0;
            background: transparent;
            font-size: 1em;
            font-family: inherit;
        }

        input {
            width: 100%;
            border-bottom: 1px solid #ccc;

            &:focus {
                border-bottom: 1px solid #1a73e8;
            }
        }
    }
}
</style>